*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

// Break line when text is too long: "this is a super long text"
.line-break {
  white-space: pre-wrap;
}

// Break line when word is too long: "thisisasuperlongword"
.break-content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.no-wrap {
  white-space: nowrap;

  &-header {
    white-space: nowrap;
  }
}

.no-wrap-columns {
  flex-wrap: nowrap;
  overflow: scroll;
}

.treeview-selected {
  background-color: #ccc;
}

.font-size-12 {
  font-size: 12px;
}

.card-sm {
  padding: 7px;
}

.w-98 {
  width: 98% !important;
  min-width: 98% !important;
}

.search-results-block {
  position: absolute;
  background-color: white;
  z-index: 999;
  width: 64%;
  padding: 10px;
  box-shadow: #ccc 0px 0 6px;
  margin-top: -4px;
  right: 11px;
}

.btn {
  &-link {
    text-decoration: none;
    padding: 0;
    margin: 0;
  }
}